/* Mixins */
.facility-config-component .page-body {
  background-color: #fff;
  padding: 20px;
}
.facility-config-component .page-body .jtl-button-component {
  margin-left: 125px;
}
.facility-config-component .page-body .card-item {
  display: inline-block;
  width: 650px;
  min-height: 300px;
  margin: 20px 20px 0 0;
  vertical-align: top;
}
.facility-config-component .page-body .card-item .dhc-loading {
  min-height: 200px;
}